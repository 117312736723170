import * as React from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  CssBaseline,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { auth } from "src/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useToken } from "../TokenProvider";
import { useState } from "react";

export default function SignIn(): JSX.Element {
  const MainContent = styled(Box)(
    ({ theme }) => `
        min-height: 100vh;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e3dede;
    `,
  );

  const navigate = useNavigate();
  const { fetchToken } = useToken();
  const [loading, setLoading] = useState<boolean>(false);

  const Login = async (email: string, password: string): Promise<void> => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await fetchToken();
        navigate("/friend");
      })
      .catch((error) => {
        alert(error.message);
        console.error(error);
        setLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;
    void handleLogin(email, password);
  };

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      setLoading(true);
      await Login(email, password);
    } catch (error) {
      console.error("Login failed:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(255, 255, 255, 0.3)",
        }}
      >
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <MainContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            boxShadow: 20,
            borderRadius: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 3 }}>
            <img src="/static/images/status/logo256.svg" height={100} />
          </Box>
          <Typography component="h1" variant="h5">
            ログイン
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              ログイン
            </Button>
          </Box>
        </Box>
      </Container>

      <Container component="sub" maxWidth="xs">
        <Box mt={6} textAlign="center">
          あしらせアカウントをお持ちでない場合は、アプリから新規アカウントの作成を行ってください。
        </Box>

        <Box mt={2} textAlign="center">
          <a
            href="https://apps.apple.com/jp/app/%E3%81%82%E3%81%97%E3%82%89%E3%81%9B/id1631484935?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "167px",
              height: "55px",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              decoding="async"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&amp;releaseDate=1674691200"
              alt="アプリダウンロードボタン"
              style={{
                borderRadius: "13px",
                width: "167px",
                height: "55px",
              }}
            />
          </a>
        </Box>
      </Container>
    </MainContent>
  );
}
